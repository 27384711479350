import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Routes } from '../constants/Routes';
import Title from '../components/title';
import { IFluidObject } from 'gatsby-background-image';
import Lightbox from '../components/Lightbox';
import styled from 'styled-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const GALERIE_QUERY = graphql`
  {
    allContentfulGalerie {
      nodes {
        titel
        jahr
        youtubeLinks
        beschreibung {
          json
        }
        bilder {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;

const Grid = styled.div`
  @media screen and (min-width: 500px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    width: 100%;
    margin: 0 auto;
    height: 100%;
    grid-gap: 1rem;
    justify-items: center;
    margin-top: 2rem;
  }
`;

type Aufführung = {
  title: string;
  description: any;
  jahr: number;
  bilder: IFluidObject[];
  youtubeUrls: string[];
};

const GaleriePage: FC = () => {
  const data = useStaticQuery(GALERIE_QUERY).allContentfulGalerie;
  const aufführungen: Aufführung[] = data.nodes.filter(Boolean).map(node => ({
    title: node.titel,
    description: node.beschreibung,
    bilder: node.bilder.map(b => b.fluid),
    jahr: node.jahr,
    youtubeUrls: node.youtubeLinks || []
  }));

  return (
    <Layout location={Routes.GALERIE}>
      <SEO title="Galerie" />
      <div className="pt-8 flex justify-center flex-col items-center" style={{ maxWidth: '100vw' }}>
        <Title className="mb-4 text-center">Aufführungen</Title>
        <Grid className="w-5/6">
          {aufführungen
            .slice()
            .sort((a, b) => b.title.includes("Die kleine Hexe") ? -1 : 1)
            .sort((a, b) => b.jahr - a.jahr)
            .map(a => (
              <div
                key={a.title + Math.random()}
                className="transition-all transition-250 hover:shadow-xl my-8 rounded shadow-lg"
                style={{ width: '20rem' }}
              >
                <Lightbox galleryButtonVisible={true} youtubeUrls={a.youtubeUrls} images={a.bilder} startAtNextPhoto />
                <div className="px-6 py-4">
                  <div className="font-bold text-xl mb-2">{a.title}</div>
                  {documentToReactComponents(a.description.json)}
                </div>
              </div>
            ))}
        </Grid>
      </div>
    </Layout>
  );
};

export default GaleriePage;
